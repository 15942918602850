/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Convo
 */
export interface Convo {
    /**
     * 
     * @type {string}
     * @memberof Convo
     */
    convoId: string;
    /**
     * 
     * @type {Date}
     * @memberof Convo
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof Convo
     */
    title: string;
}

export function ConvoFromJSON(json: any): Convo {
    return {
        'convoId': json['convoId'],
        'created': new Date(json['created']),
        'title': json['title'],
    };
}

export function ConvoToJSON(value?: Convo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'convoId': value.convoId,
        'created': value.created.toISOString(),
        'title': value.title,
    };
}


