/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    ConvoMessage,
    ConvoMessageFromJSON,
    ConvoMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConvoDetailsResponse
 */
export interface ConvoDetailsResponse {
    /**
     * 
     * @type {Array<ConvoMessage>}
     * @memberof ConvoDetailsResponse
     */
    results: Array<ConvoMessage>;
}

export function ConvoDetailsResponseFromJSON(json: any): ConvoDetailsResponse {
    return {
        
        'results': (json['results'] as Array<any>).map(ConvoMessageFromJSON),
        
    };
}

export function ConvoDetailsResponseToJSON(value?: ConvoDetailsResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'results': (value.results as Array<any>).map(ConvoMessageToJSON),
        
    };
}


