/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// eslint-disable-next-line no-unused-vars
import * as runtime from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    ConvoDetailsResponse,
    ConvoDetailsResponseFromJSON,
    ConvoDetailsResponseToJSON,
    ConvoListResponse,
    ConvoListResponseFromJSON,
    ConvoListResponseToJSON,
    ConvoMessageCreate,
    ConvoMessageCreateFromJSON,
    ConvoMessageCreateToJSON,
    CreateMessageResponse,
    CreateMessageResponseFromJSON,
    CreateMessageResponseToJSON,
} from '../models';

export interface ConvoDeleteRequest {
    projectId: string;
    convoId: string;
}

export interface ConvoDetailsRequest {
    projectId: string;
    convoId: string;
}

export interface ConvoListRequest {
    projectId: string;
}

export interface MessageCreateRequest {
    projectId: string;
    convoId: string;
    convoMessageCreate: ConvoMessageCreate;
}

export interface MessageStreamGetRequest {
    projectId: string;
    convoId: string;
    messageId: string;
}

/**
 * no description
 */
export interface LlmAdminApiInterface {

    /**
     */
    convoDelete(requestParameters: ConvoDeleteRequest, headerExtra?:{[key:string]:string}): Promise<void>;

    /**
     */
    convoDetails(requestParameters: ConvoDetailsRequest, headerExtra?:{[key:string]:string}): Promise<ConvoDetailsResponse>;

    /**
     */
    convoList(requestParameters: ConvoListRequest, headerExtra?:{[key:string]:string}): Promise<ConvoListResponse>;

    /**
     */
    messageCreate(requestParameters: MessageCreateRequest, headerExtra?:{[key:string]:string}): Promise<CreateMessageResponse>;

    /**
     */
    messageStreamGet(requestParameters: MessageStreamGetRequest, ): EventSource;
}

/**
 * no description
 */
export class LlmAdminApi extends runtime.BaseAPI implements LlmAdminApiInterface {

    /**
     */
    async convoDeleteRaw(requestParameters: ConvoDeleteRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling convoDelete.');
        }

        if (requestParameters.convoId === null || requestParameters.convoId === undefined) {
            throw new runtime.RequiredError('convoId','Required parameter requestParameters.convoId was null or undefined when calling convoDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/llm/convo/{convoId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"convoId"}}`, encodeURIComponent(String(requestParameters.convoId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async convoDelete(requestParameters: ConvoDeleteRequest, headerExtra?:{[key:string]:string}): Promise<void> {
        await this.convoDeleteRaw(requestParameters, headerExtra);
    }

    /**
     */
    async convoDetailsRaw(requestParameters: ConvoDetailsRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<ConvoDetailsResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling convoDetails.');
        }

        if (requestParameters.convoId === null || requestParameters.convoId === undefined) {
            throw new runtime.RequiredError('convoId','Required parameter requestParameters.convoId was null or undefined when calling convoDetails.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/llm/convo/{convoId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"convoId"}}`, encodeURIComponent(String(requestParameters.convoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConvoDetailsResponseFromJSON(jsonValue));
    }

    /**
     */
    async convoDetails(requestParameters: ConvoDetailsRequest, headerExtra?:{[key:string]:string}): Promise<ConvoDetailsResponse> {
        const response = await this.convoDetailsRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async convoListRaw(requestParameters: ConvoListRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<ConvoListResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling convoList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        const response = await this.request({
            path: `/project/{projectId}/admin/llm/convo/list`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConvoListResponseFromJSON(jsonValue));
    }

    /**
     */
    async convoList(requestParameters: ConvoListRequest, headerExtra?:{[key:string]:string}): Promise<ConvoListResponse> {
        const response = await this.convoListRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    async messageCreateRaw(requestParameters: MessageCreateRequest, headerExtra?:{[key:string]:string}): Promise<runtime.ApiResponse<CreateMessageResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling messageCreate.');
        }

        if (requestParameters.convoId === null || requestParameters.convoId === undefined) {
            throw new runtime.RequiredError('convoId','Required parameter requestParameters.convoId was null or undefined when calling messageCreate.');
        }

        if (requestParameters.convoMessageCreate === null || requestParameters.convoMessageCreate === undefined) {
            throw new runtime.RequiredError('convoMessageCreate','Required parameter requestParameters.convoMessageCreate was null or undefined when calling messageCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = headerExtra || {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}/admin/llm/convo/{convoId}/message`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"convoId"}}`, encodeURIComponent(String(requestParameters.convoId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConvoMessageCreateToJSON(requestParameters.convoMessageCreate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateMessageResponseFromJSON(jsonValue));
    }

    /**
     */
    async messageCreate(requestParameters: MessageCreateRequest, headerExtra?:{[key:string]:string}): Promise<CreateMessageResponse> {
        const response = await this.messageCreateRaw(requestParameters, headerExtra);
        return await response.value();
    }

    /**
     */
    messageStreamGetRaw(requestParameters: MessageStreamGetRequest, ): EventSource {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling messageStreamGet.');
        }

        if (requestParameters.convoId === null || requestParameters.convoId === undefined) {
            throw new runtime.RequiredError('convoId','Required parameter requestParameters.convoId was null or undefined when calling messageStreamGet.');
        }

        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId','Required parameter requestParameters.messageId was null or undefined when calling messageStreamGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = new EventSource(this.createFetchParams({
            path: `/project/{projectId}/admin/llm/convo/{convoId}/message/{messageId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"convoId"}}`, encodeURIComponent(String(requestParameters.convoId))).replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters.messageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }).url);

        return response;
    }

    /**
     */
    messageStreamGet(requestParameters: MessageStreamGetRequest, ): EventSource {
        return this.messageStreamGetRaw(requestParameters, );
    }

}
