/* tslint:disable */
/* tslint:disable */
/**
 * ClearFlask Client API
 * ClearFlask API documentation.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import { exists } from '../runtime';
// eslint-disable-next-line no-unused-vars
import {
    Convo,
    ConvoFromJSON,
    ConvoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConvoListResponse
 */
export interface ConvoListResponse {
    /**
     * 
     * @type {Array<Convo>}
     * @memberof ConvoListResponse
     */
    results: Array<Convo>;
}

export function ConvoListResponseFromJSON(json: any): ConvoListResponse {
    return {
        
        'results': (json['results'] as Array<any>).map(ConvoFromJSON),
        
    };
}

export function ConvoListResponseToJSON(value?: ConvoListResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        
        'results': (value.results as Array<any>).map(ConvoToJSON),
        
    };
}


